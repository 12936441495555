<div class="offcanvas offcanvas-start" tabindex="-1" id="portals" aria-labelledby="portals_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="portals_label">Portals</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <table v-if="portals.length" class="table mb-0">
      <tbody>
        <tr v-for="portal in portals" :key="portal.id" class="hover-row border-0 ">
          <td class="border-0">
            <span class="text-decoration-none text-dark" @click="handlePortalClick(portal)" data-bs-dismiss="offcanvas">
              <p class="portal-icon mb-0 d-flex align-items-center">
                <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                <span class="text-xs fw-500 mx-2">{{ portal.name }} <i v-if="!portal.is_public"
                    class='bx bxs-lock text-primary text-xxs'></i></span>
              </p>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="col-lg-3 d-none d-xl-block mt-4">
  <div class="mx-5 rounded-5 p-2 pb-5 position-sticky top-20px">
    <h6 class='text-start mx-1'>Portals</h6>
    <hr />

    <div class="table-container">
      <table v-if="portals.length" class="table mb-0">
        <tbody>
          <tr v-for="portal in portals" :key="portal.id" class="hover-row border-0 ">
            <td class="border-0">
              <span class="text-decoration-none text-dark" @click="handlePortalClick(portal)">
                <p class="portal-icon mb-0 d-flex align-items-center">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                  <span class="text-xs fw-500 mx-2">{{ portal.name }} <i v-if="!portal.is_public"
                      class='bx bxs-lock text-primary text-xxs'></i></span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else class="text-secondary text-xs text-center">
        No Available Portals
      </p>
    </div>
  </div>



  <div class="mx-5 rounded-5 p-2 pb-5 mt-2 d-none d-md-block">
    <div v-show="!is_public">
      <h6>Portal Request</h6>
      <hr />
      <div class="table-container">
        <table v-if="requested_users.length" class="table table-hover align-middle mb-0">
          <tbody>
            <tr v-for="member in requested_users" :key="member.id" class="hover-row border-bottom">
              <td class="border-0">
                <div class="d-flex align-items-center">
                  <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                    <i class='bx bxs-user fs-5'></i>
                  </span>
                  <span class="text-sm fw-bold mx-3">
                    <a :href="getProfileLink(member)"
                       class="text-decoration-none text-dark">
                      {{ member.first_name }} {{ member.last_name }}
                    </a>
                  </span>

                </div>
              </td>
              <td class="border-0 text-end">
                <button @click="approve_portal_access_request(member.id)" class="btn p-0 m-0 transparent" title="Admit User">
                  <i class="bi bi-check-circle fs-2 text-success"></i>
                </button>
                <button @click="declineRequest(member.id)" class="btn p-0 m-0 transparent ms-1" title="Decline User">
                  <i class="bi bi-x-circle fs-2 text-danger"></i>
                </button>
              </td>


            </tr>
          </tbody>
        </table>
        <p v-else class="text-secondary text-xs text-center">No Users Available</p>
      </div>
    </div>
  </div>

</div>

<div class="modal fade" id="request_access" tabindex="-1" aria-labelledby="request_accessModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-5">
      <div class="modal-header">
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <p class="text-md fw-500">This is a private portal. Would you like to request access?</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button @click="requestAccess" class="btn btn-primary text-xs rounded-5 shadow">Request Access</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="create_portal" tabindex="-1" aria-labelledby="create_portalModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content rounded-5">
      <form @submit.prevent="createPortal">
        <div class="modal-body">
          <h6 class="modal-title mb-3" id="create_portalModalLabel">Create Portal</h6>

          <div class="mb-3">
            <label for="portal_name" class="form-label text-xs fw-500">Portal name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="portal_name" placeholder="Enter portal name"
              v-model="portal_name" required>
            <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
          </div>
          <div class="mb-3">
            <label for="is_public" class="form-label text-xs fw-500">Visibility<span
                class="text-danger">*</span></label>
            <select class="form-select rounded-5 text-xs" id="is_public" v-model="is_public" required>
              <option selected hidden>Select visibility type</option>
              <option value="false">Private</option>
              <option value="true">Public</option>
            </select>
          </div>
          <div class="float-end mb-3">
            <button type="button" class="btn btn-primary text-xs rounded-5 shadow mx-1"
              data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary text-xs rounded-5 shadow">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-start" tabindex="-1" id="requested_members" aria-labelledby="portal_members_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="portal_label"></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
      <h6 class='text-start mx-1 d-flex justify-content-between align-items-center'>Portal Request Members
        </h6>

      <div class="table-container ">
        <table v-if="requested_users.length" class="table mb-0">
          <tbody>
            <tr v-for="member in requested_users" :key="member.id" class="hover-row border-0">
              <td class="border-0">
                <span class="text-decoration-none text-dark">
                  <p class="portal-icon mb-0 d-flex align-items-center">
                    <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                      <i class='bx bx-user fs-5'></i>
                    </a>
                    <span class="text-xs fw-500 mx-2 color-blue">{{ member.first_name }} {{ member.last_name }}</span>
                  </p>
                </span>
              </td>
              <td class="border-0 text-end">
                <button @click="approve_portal_access_request(member.id)" class="btn p-0 m-0 transparent" title="Admit User">
                  <i class="bi bi-check-circle fs-2 text-success"></i>
                </button>
                <button @click="declineRequest(member.id)" class="btn p-0 m-0 transparent ms-1" title="Decline User">
                  <i class="bi bi-x-circle fs-2 text-danger"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-secondary text-xs text-center">
          No Portal members Available
        </p>
      </div>
  </div>
</div>