import {get_token} from "@/router";
import Swal from 'sweetalert2';

export default {
  mounted() {
    this.fecthTopics();
    this.fetchPortals();
    this.fetch_users();
    this.fetchPortalMembers(); 
    this.token_exists = get_token();
  },
  props: {
    topics: {
      type: Array,
      required: true
    }},

    watch: {
      '$route.params.portal_id'() {
        this.fecthTopics();
        this.fetchPortals();
        this.fetchPortalMembers();
        this.fetch_users();
      }
    },

  data() {
    return ({
      topics:[],
      portals: [],
      members: [],
      users:[],
      topic:'',
      selectedUserIds:[],
      topic_id:'',
      portal_id:'',
      is_public: true,
      isLoading: false,
      token_exists: false,
      topic_name: '',
      isSubmitting: false,
      errors: {},
    
    });
  },

  methods: {
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },

  handleTopicClick(topicId) {
    this.$emit('topic_selected', topicId); 
  },

    fecthTopics() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: {portal_id: this.$route.params.portal_id},
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
          }

        },

        complete: () => {
          this.isLoading = false;
        }
      });
    },

    fetchPortals() {
      this.isLoading = true;
      this.portal_id=this.$route.params.portal_id
      $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
      method: 'GET',
      headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
      },
      success: (data) => {
          this.portals = data.portals;
          const visible=this.portals.find(portal_id => portal_id.id === parseInt(this.portal_id, 10));
          if (visible) {
            this.is_public = visible.is_public;
          }
      },
      complete: () => {
          this.isLoading = false;
      }
      });
  },

  fetch_users() {
    return new Promise((resolve) => {

      const portalId = this.$route.params.portal_id;
    $.ajax({
      type: 'GET',
      data:{portal_id:portalId},
      url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
      headers: {
        'Authorization': `Token ${this.getToken()}`,
      },
      success: (response) => {
        this.users = response.data.users_list;
        resolve();
      },
    });
  });
  },

  addPortalMembers() {
    if (this.selectedUserIds.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select at least one  user.',
        showConfirmButton: true
      });
      return;
    }
    this.isSubmitting = true;
    const requestData = {
      portal_id: this.$route.params.portal_id,
      user_id: this.selectedUserIds,
    };
    $.ajax({
      method: "POST",
      url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_portal_members/`,
      contentType: "application/json",
      headers: {
        'Authorization': `Token ${this.getToken()}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(requestData),
      success: (response) => {
        const status = response.status;
        const newMembers = response.members.map(member => member.user);
        
        if (status === "success") {
          Swal.fire({
            icon: 'success',
            title: 'Members Added Successfully',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            newMembers.forEach(user => {
              this.members.push(user);});
            this.selectedUserIds = [];
            $('#add_portal_member_modal').modal('hide');
          });
        } 
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  },

  fetchPortalMembers() {
    this.isLoading = true;
    $.ajax({
    url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_portal_members/`,
    method: 'GET',
    data: {portal_id: this.$route.params.portal_id},
    headers: {
        'Authorization': `Token ${this.getToken()}`,
        'Content-Type': 'application/json'
    },
    success: (response) => {

      if(response.status=="success"){
        this.members=response.data
      }

    },
    complete: () => {
        this.isLoading = false;
    }
    });
},

    handleSubmit() {
      if (this.validateTopicForm()) { 
        this.isSubmitting = true;

        const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(requestData),
        success: (response) => {
          
          const status = response.status;
          
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Segment Created Successfully',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              window.location.reload();
              $('#create_topic').modal('hide');
            });
          } else if (status === "error") {
            Swal.fire({
              icon: 'error',
              title: 'Creation Failed',
              text: response.message || 'An error occurred. Please try again.',
              confirmButtonText: 'Retry'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Unexpected Response',
              text: 'The server returned an unexpected response.',
              confirmButtonText: 'Okay'
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if(response_data.status=="error"){
           this.errors.topic_name=response_data.message
          }
      },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    }
   },


   validateTopicForm() {
    this.errors = {};
    let isValid = true;
    if (!this.topic_name.trim()) {
      this.errors.topic_name = 'Segment name is required.';
      isValid = false;
    }
    return isValid;
  },
  },
};
