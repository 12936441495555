import { get_token, get_user_details } from "@/router";
import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      first_name: '',
      last_name: '',
      email: '',
      user_role: '',
      phone_number: '',
      roles: [],
      users: [],
      errors: {},
      logged_in_user_id: null,
      edit_form: {
        user_id: null,
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        user_role: '',
        active_tab: 'users',
      },
    });
  },
  mounted() {
    this.fetch_users().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true, 
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          }
        });
      });
    });
    this.fetch_roles()
    const saved_tab = localStorage.getItem('active_tab');
  if (saved_tab) {
    this.active_tab = saved_tab;
   }
  },
  computed: {
    filteredUsers() {
      return this.users
      
    },
  
    filteredAdmin() {
      return this.users.filter(user => user.role.role === 'admin') 
    },
    filteredClient() {
     return this.users.filter(user => user.role.role === 'client') 
    },
    filteredCustomer() {
      return  this.users.filter(user => user.role.role === 'customer') 
    },
    filteredStakeholder() {
      return this.users.filter(user => user.role.role === 'stakeholder') 
    },
    filteredEditor() {
      return this.users.filter(user => user.role.role === 'editor') 
    },
  },
  
  methods: {
    getToken() {
      return get_token();
    },
    setactive_tab(tab) {
      this.active_tab = tab;
      // Save the active tab to localStorage
      localStorage.setItem('active_tab', tab);
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },

    validationUserForm() {
      this.errors = {};
      let valid = true;
      // const form = form_type === 'edit' ? this.edit_form : this;

      if (!this.first_name) {
        this.errors.first_name = 'First name is required.';
        valid = false;
      } else if (this.first_name.length < 3) {
        this.errors.first_name = 'First name must be at least 3 characters long.';
        valid = false;
      } else if (/[^a-zA-Z\s]/.test(this.first_name)) {
        this.errors.first_name = 'First name must only contain letters and spaces.';
        valid = false;
      }
      if (!this.last_name) {
        this.errors.last_name = 'Last name is required.';
        valid = false;
      } else if (this.last_name.length < 3) {
        this.errors.last_name = 'Last name must be at least 3 characters long.';
        valid = false;
      } else if (/[^a-zA-Z\s]/.test(this.last_name)) {
        this.errors.last_name = 'Last name must only contain letters and spaces.';
        valid = false;
      }
      if (!this.email.trim()) {
        this.errors.email = 'Email is required.';
        valid = false;
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.errors.email = 'Please enter a valid email address.';
        valid = false;
      }
      if (!this.user_role) {
        this.errors.user_role = 'User role is required.';
        valid = false;
      }
      if (!this.phone_number) {
        this.errors.phone_number = 'Phone number is required.';
        valid = false;
      } else if (!/^0\d{9}$/.test(this.phone_number)) {
        this.errors.phone_number = 'Phone number must start with 0 and be exactly 10 digits long.';
        valid = false;
      }

      return valid;
    },

    submit_form() {
      if (!this.validationUserForm('create')) {
        return;
      }
      Swal.fire({
        title: 'Saving...',
        text: 'Please wait while we save the user data.',
        allowOutsideClick: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      const protocol = window.location.protocol;
      const host = window.location.host;
      var login_url = `${protocol}//${host}/sign_in_Finale`;

      const data = {
        email: this.email,
        role: this.user_role,
        login_url: login_url,
        last_name: this.last_name,
        first_name: this.first_name,
        phone_number: this.phone_number,
      };

      $.ajax({
        type: 'POST',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/create_user/',
        data: JSON.stringify(data),
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          const status = response.status;

          if (status === "success") {
            Swal.fire({
              icon: "success",
              title: "User Created",
              showConfirmButton: false,
              timer: 1000 
            }).then(() => {
              this.users.unshift(response.user);
            });
            this.resetForm();
            $('#create_user').modal('hide');
          }
        },
        error: (response) => {
          const response_data=response.responseJSON
          Swal.close()
          if(response_data.status=='error'){
            const message = response_data.message.toLowerCase();
            if (message.includes('email')) {
                this.errors.email = response_data.message;
            } else if (message.includes('phone number')) {
                this.errors.phone_number = response_data.message;
            } 

          }
          
        }
      });
    },

    resetForm() {
      this.first_name = '';
      this.last_name = '';
      this.email = '';
      this.phone_number = '';
      this.user_role = '';
      this.errors = {};
    },

    fetch_users() {
      return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
        data: { email: this.$route.query.email },
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          this.users = response.data.users_list;
          const logged_in_user = get_user_details();

        if (logged_in_user) {
          this.logged_in_user_id = logged_in_user.user_id;
                }
          resolve();
        },
      });
    });
    },


    fetch_roles() {
      $.ajax({
        type: 'GET',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/get_roles/',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          const response_roles = response.data;
          this.roles = response_roles.role_data;
        },
      });
    },

    confirm_deactivation(user_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deactivate it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deactivate_user(user_id);
        }
      });
    },

    deactivate_user(user_id) {
      $.ajax({
        type: 'POST',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/deactivate_user/',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: {
          user_id: user_id
        },
        success: (response) => {
          const status = response.status;
          const message = response.message;

          if (status === "success") {
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000 
            }).then(() => {
              window.location.reload();
            });

          } else if (status === "error") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
            });
          }
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was a problem deactivating the user. Please try again.",
            confirmButtonText: "OK"
          });
        }
      });
    },

    confirm_activation(user_id) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.activate_user(user_id);
        }
      });
    },

    activate_user(user_id) {
      $.ajax({
        type: 'POST',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/activate_user/',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { user_id: user_id },
        success: (response) => {
          const status = response.status;
          const message = response.message;

          if (status === "success") {
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1000 
            }).then(() => {
              window.location.reload();
            });
          } else if (status === "error") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
            });
          }
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was a problem activating the user. Please try again.",
            confirmButtonText: "OK"
          });
        }
      });
    },

    edit_user(user) {
      this.edit_form = { 
        user_id: user.id, 
        first_name: user.first_name, 
        last_name: user.last_name, 
        phone_number: user.phone_number, 
        email: user.email, 
        user_role: user.role.role 
      };
      this.reset_errors();
      $('#edit_user').modal('show');
    },

    update_user() {

      Swal.fire({
        title: 'Updating...',
        text: 'Please wait while we update the user data.',
        allowOutsideClick: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      const data = {
        user_id: this.edit_form.user_id,
        first_name: this.edit_form.first_name,
        last_name: this.edit_form.last_name,
        email: this.edit_form.email,
        phone_number: this.edit_form.phone_number,
        role: this.edit_form.user_role
      };
      $.ajax({
        type: 'POST',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/update_user/',
        data: JSON.stringify(data),
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          const status = response.status;
          const message = response.message;

          if (status === "success") {
            Swal.fire({
              icon: "success",
              title: "User Updated",
              showConfirmButton: false,
              timer: 1000 
            }).then(() => {
              window.location.reload();
            });
            $('#edit_user').modal('hide');
          } else if (status === "error") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
            });
          }
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was a problem updating the user. Please try again.",
            confirmButtonText: "OK"
          });
        }
      });
    },

    reset_edit_form() {
      this.edit_form = {
        user_id: null,
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        user_role: ''
      };
      this.reset_errors();
    },

    reset_errors() {
      this.errors = {};
    },

    delete_user(user_id) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {

          const request_data = JSON.stringify({ user_id: user_id });

          const headers = {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          };

          const url = `${process.env.VUE_APP_BACKEND_API_URL}/system_management/delete_user/`;

          $.ajax({
            type: 'DELETE',
            url: url,
            headers: headers,
            data: request_data,
            contentType: 'application/json',
            success: (response) => {

              const status = response.status;

              if (status === "success") {
                this.users = this.users.filter(user => user.id !== user_id);
                Swal.fire(
                  {
                    icon: 'success',
                    title: 'User deleted',
                    showConfirmButton: false,
                    timer: 2000
                  }
                );
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Sorry',
                  text: 'Could not delete user.',
                  confirmButtonText: 'OK'
                });
              }
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Sorry',
                text: 'Could not delete user.',
                confirmButtonText: 'OK'
              });
            }
          });
        }
      });
    },

  }
};